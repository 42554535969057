/**
 * base64转byte[] 再转Blob
 * @param base64String
 */
export function Base64ToBlob (base64String) {
  // 对base64进行解码
  const fileContent = atob(base64String)
  // 将字节数组转换为Uint8Array
  const bytes = new Uint8Array(fileContent.length)
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = fileContent.charCodeAt(i)
  }
  return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
}
