import { errorTip, confirmTip, successTip } from '@/utils/Tip'
import { Base64ToBlob } from '@/utils/conversion'
import { saveAs } from 'file-saver'

export default class BaseService {
  request = null
  controller = null

  // 初始话
  init (controller, request) {
    this.controller = controller
    this.request = request
    return this
  }

  // 获取列表数据
  async getList () {
    const result = await this.request.listPost({ ...this.controller.pageData, ...this.controller.searchForm })
    if (result.code !== 200) errorTip(this.controller, result.message)
    this.controller.pageData = result.data
  }

  // 导入事件
  async importClick (file) {
    const formData = new FormData()
    formData.append('file', file)
    const result = await this.request.importPost(formData)
    result.code === 200 ? successTip(this.controller, result.message) : errorTip(this.controller, result.message)
  }

  // 导出事件
  async exportClick () {
    const result = await this.request.exportPost({ ...this.controller.searchForm, ...this.controller.pageData })
    if (result.code !== 200) errorTip(this.controller, result.message)
    // 将byt[]转换为blob
    const blob = Base64ToBlob(result.data)
    // 下载文件
    saveAs(blob, Date.now().toString())
  }

  // 提交事件
  async submitClick () {
    const result = (this.controller.Scene === 1)
      ? await this.request.addPost({ ...this.controller.form })
      : await this.request.editPost({ ...this.controller.form })

    if (result.code !== 200) errorTip(this.controller, result.message)
    // 更新表格数据
    await this.getList()
    // 关闭弹窗
    this.cancelDialog()
    successTip(this.controller, result.message)
  }

  // 删除事件
  async deleteClick (row) {
    // 询问框
    const status = await confirmTip(this.controller, '确定删除吗?')
    if (!status) return false

    // 发起请求
    const result = await this.request.deletePost(row)
    if (result.code !== 200) errorTip(this.controller, result.message)
    // 更新表格数据
    await this.getList(this.controller.pageData, this.controller.searchForm)
    successTip(this.controller, result.message)
  }

  // 重置事件
  async resetClick () {
    // 重置搜索框
    this.controller.$data.searchForm = this.controller.$options.data.call(this.controller).searchForm
    // 刷新页面数据
    await this.getList(this.controller.pageData, this.controller.searchForm)
  }

  // 分页事件
  async pagingClick (val) {
    // 第几页
    this.controller.pageData.page = Number(val)
    // 获取列表
    await this.getList()
  }

  // 编辑事件
  async editClick (item) {
    this.controller.showDialog = true
    this.controller.Scene = 2
    // 注意这里不可以直接相等，要使用深拷贝，防止点击取消或者关闭时出错误
    this.controller.form = JSON.parse(JSON.stringify(item))
  }

  // 取消事件
  cancelDialog () {
    this.controller.showDialog = false
    this.controller.Scene = null
    setTimeout(() => {
      this.controller.$data.form = this.controller.$options.data.call(this.controller).form
    }, 500)
  }

  // 添加事件
  addClick () {
    this.controller.showDialog = true
    this.controller.Scene = 1
  }
}
